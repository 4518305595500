/*
 * @Descripttion: 全能组件json
 * @version: 
 * @Author: qianlishi
 * @Date: 2021-08-29 07:20:11
 * @LastEditors: qianlishi
 * @LastEditTime: 2021-08-29 07:26:02
 */
export const widgetUniversal = {
    code: 'widget-universal',
    type: 'html',
    label: '全能组件',
    icon: 'univresal',
  }